import React from 'react'
import {
	navigate
} from 'gatsby'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import {Header, Container} from 'semantic-ui-react'
import * as styles from './styles/pages.module.css'


//const Negosyo = require('../../lib/negosyo')


const NotFoundPage = ({location}) => {
	const urlPaths = location.pathname.split('/')
	const mainPath = urlPaths[1]
	if(mainPath==="invite" && urlPaths.length === 3 && urlPaths[2]!=="") {
		if(!localStorage.getItem("referral")) {
			let referralCode = urlPaths[2]
			referralCode = decodeURIComponent(referralCode)
			localStorage.setItem("referral", referralCode)
		}
		navigate('/')
		return ""
	} else if(mainPath==="seminarinvite" && urlPaths.length === 4 && urlPaths[3]!=="") {
		if(!localStorage.getItem("seminarreferral")) {
			let referralCode = urlPaths[3]
			referralCode = decodeURIComponent(referralCode)
			localStorage.setItem("seminarreferral", referralCode)
		}
		console.log(urlPaths[2]);
		navigate('/seminar/register/'+urlPaths[2])
		return ""
	}
	return (
		<Layout location={location}>
			<Seo title={"Page not found"} description={"Page not found"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<Header className={styles.superHeaderMod}>Page not found</Header>
				<div className={`${styles.text} ${styles.centerText} ${styles.errorText}`} >
					Sorry we couldn't find the page you were looking for.<br/>
				</div>
			</Container>
		</Layout>
	)
}

export default NotFoundPage
